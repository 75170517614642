import { isDateBeforeToday } from "common/calendar";

const getIdDobYear = (value) => {
	const nricLast4Digit = value.slice(10, 14);

	const years90 = parseInt(nricLast4Digit[0]) >= 5;

	let dobYear = parseInt(value.slice(0, 2));

	if (!years90) {
		dobYear = 2000 + dobYear;
	} else {
		dobYear = 1900 + dobYear;
	}
	return dobYear;
};

export const isValidDate = (dateObj) => {
	return new Date(dateObj).toString() !== "Invalid Date";
};

export const getDateOfBirthNRIC = (value) => {
	let nextDob = "";

	if (value && value.length >= 14) {
		const dob = value.slice(0, 6);
		const dobMonth = dob.slice(2, 4);
		const dobDay = dob.slice(4, 6);
		const dobYear = getIdDobYear(value);
		const d = new Date(`${dobYear}-${dobMonth}-${dobDay}`);

		const isValid = isValidDate(d);

		if (isValid) nextDob = d;
	}

	return nextDob;
};

export const validateNRIC = (value) => {
	let isValid = false;

	if (value?.length >= 14) {
		const dob = value.slice(0, 6);
		const dobMonth = dob.slice(2, 4);
		const dobDay = dob.slice(4, 6);
		const dobYear = getIdDobYear(value);

		const d = new Date(`${dobYear}-${dobMonth}-${dobDay}`);

		isValid = isValidDate(d) && isDateBeforeToday(d, new Date());
	}

	return isValid;
};

export const getDateOfBirthNRICNew = (icNumber) => {
	let formattedDate = "";

	if (icNumber && icNumber.length >= 14) {
		// Assuming the NRIC format is yymmddXXXXXX
		const year = icNumber.substring(0, 2);
		const month = icNumber.substring(2, 4);
		const day = icNumber.substring(4, 6);
		
		// Determine the century for the year
		const currentYear = new Date().getFullYear() % 100;
		const century = year <= currentYear ? '20' : '19';
		
		// Construct the full year
		const fullYear = century + year;
		
		// Format the date as dd/mm/yyyy
		formattedDate = `${day}/${month}/${fullYear}`;
	}
  
	return formattedDate;
  }