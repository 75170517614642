import React, { useEffect, useRef, useMemo, useState } from "react";
import * as yup from "yup";

import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import pathnames from "routes/pathnames";
import AppButton from "components/app-button";
import { resetForm } from "store/slices/form";
import logo from "assets/images/pa_logo.png";
import AppInput from "components/app-input";
import referralIcon from "assets/images/pages/page-summary/referral-icon.svg";
import { setReferralCode } from "store/slices/form";
import Turnstile from 'react-turnstile';
import ERRORS from "common/errors";

const PageHome = () => {
	const timer = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const referralCode = urlParams.get("myReferralCode");
	const [turnstileToken, setTurnstileToken] = useState(null);
	const sitekey = process.env.REACT_APP_TURNSTILE_SITE_KEY;

	const initialValues = useMemo(() => {
		return {
		  refCode: referralCode || "",
		};
	  }, [referralCode]);

	const onHandleNavigateNext = () => {
		navigate(pathnames.decript);
	};

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			refCode: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const onHandleSubmit = (values) => {
		if (values.refCode && values.refCode !== "null"){
			dispatch(setReferralCode(values.refCode));
		} 

		//console.log("refcode", values.refCode);
		sessionStorage.setItem('fromHome', 'true');
		onHandleNavigateNext();
	};

	useEffect(() => {
		timer.current = setTimeout(() => {
			dispatch(resetForm());
		}, 100);

		return () => {
			clearTimeout(timer.current);
		};
	}, [dispatch]);

	return (
		<div className="page-home">
			<div className="home">
				<main className="main">
					<div className="main__content main__content--background">
						<div className="main__logo"> 
							<img src={logo} alt="logo" />
						</div>
					</div>

					<div className="main__content main__content--form">
						<div className="main__form">
							<h1 className="main__title">Redeem Your 1 - Year Complimentary RM 10,000 Personal Accident Cover!</h1>
							<p className="main__description">We're giving away 10,000 Policies. Hurry, while stocks last!</p>
							<p className="main__description-center">Download the easycover.my app now!</p>

							<div className="referral">
									<div className="referral__container">
										<div className="referral__box">
											<div className="referral__wrapper">
												<img className="referral__logo" src={referralIcon} alt="logo" />
												<p className="referral__label">Referral Code <br />(Mandatory)</p>
											</div>
								
											<div className="referral__value">
												{/*prettier-ignore*/}
												<AppInput type="text" name="refCode" label="" placeholder="Enter Referral Code" value={formik.values.refCode} error={formik.errors.refCode} touched={formik.touched.refCode} onChange={formik.handleChange} disabled={formik.isSubmitting || Boolean(referralCode)} />
											</div>

											
										</div>
									</div>
								</div>

							<div className="main__button-container">
								<Turnstile className="main__button-container__turnstile" sitekey={sitekey} onVerify= {(token) => setTurnstileToken(token)} theme="dark"/>
								<AppButton type="submit" label="Let's get started" onClick={formik.handleSubmit} /*disabled={formik.isSubmitting || !turnstileToken }*/ />
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageHome;
